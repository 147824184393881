<template>
  <el-card class="resultBox">
    <div v-if="total != 0">
      <p class="resultTitle Text">为你找到“{{ query }}”相关结果共{{ total }}条</p>
      <div class="searchResultList">
        <div class="searchResult" v-for="list in searchList" :key="list.id">
          <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-for="cate in list.categories" :key="cate.id" :to="`/help/${cate.id}?type=0`">{{
                cate.name
              }}</el-breadcrumb-item>
              <el-breadcrumb-item>{{ list.name }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="articleTitle">
            <router-link :to="`/help/${list.id}?type=1`">
              <span class="MainSmallHead">{{ list.name }}</span>
            </router-link>
          </div>
          <div class="articleContent">
            <span class="SubHead">{{ list.content }}</span>
          </div>
          <div class="articleTime">
            <span class="HelpText">{{ list.update_at }}</span>
          </div>
        </div>
      </div>

      <!-- 分页区域 -->
      <el-pagination
        v-model:currentPage="queryInfo.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
        :pager-count="5"
        :background="true"
      >
      </el-pagination>
    </div>

    <el-empty v-else description="抱歉，没有找到相关结果。换个关键词试试？"></el-empty>
  </el-card>
</template>

<script>
export default {
  name: 'SearchResult',
  props: ['query'],
  data() {
    return {
      userInfo: '',
      token: '',
      keyword: '',
      SearchResultList: [],
      // 获取结果列表的参数对象
      queryInfo: {
        query: '',
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      total: 0,
      searchList: [],
      content: ''
    }
  },
  created() {
    this.getURLParams(window.location.href)
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
      } else {
        this.token = ''
      }
    }
    this.changeWidth()
    this.$bus.on('getSearchResult', keyword => {
      this.keyword = keyword
    })
    this.$bus.on('getSearchResult', this.getSearchResult)
    this.getSearchResult()
  },
  methods: {
    changeWidth() {
      if (this.$route.name == 'search') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
    },
    async getURLParams(url) {
      var type = url.split('?')[1]
      if (!type) {
        return
      } else {
        var type1 = type.split('=')[1]
        var login = type.split('=')[0]
        var type2 = decodeURI(type.split('=')[1])
        var keyword = type2.split('&')[0]
        this.keyword = keyword

        var old_type = window.localStorage.getItem('type')
        if (login == 'login' && old_type != 1) {
          window.localStorage.setItem('type', type1)
        }
      }
    },

    // 点击按钮搜索
    async getSearchResult() {
      const { data: res } = await this.$http.post('bot/search_list', {
        keyword: this.keyword,
        token: this.token,
        page: this.queryInfo.page
      })

      this.searchList = res.data
      this.searchList.forEach((listItem, k) => {
        var reg = /<[^<>]+>/g
        this.searchList[k].content = listItem.content.replace(reg, '')
      })
      this.total = res.total
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPages) {
      this.queryInfo.page = newPages
      this.getSearchResult()
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.searchHeader {
  h2 {
    margin: 50px 0;
  }
  .searchBox {
    display: flex;
    justify-content: space-between;
    width: 600px;
    margin: 0 auto;
    :deep(.el-autocomplete) {
      width: 100%;
    }
  }
}
.resultBox {
  max-width: 1200px;
  margin: 60px auto;
  border-radius: 8px;
  opacity: 0.8;
  :deep(.el-card__body) {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 40px;
  }
  .searchResult {
    min-height: 20px;
    margin-bottom: 50px;
  }
  .resultTitle {
    margin: 0 0 32px;
  }
  .articleTitle,
  .articleContent {
    span {
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 8px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
:deep(.el-breadcrumb__inner) {
  font-size: 14px;
  color: #000;
  opacity: 0.65;
}
:deep(.el-breadcrumb__inner.is-link:hover) {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .resultBox {
    max-width: 100%;
    margin: 30px auto;
    :deep(.el-card__body) {
      padding: 20px;
    }
  }
  :deep(.el-pagination) {
    white-space: normal;
  }
  .resultTitle {
    font-size: 16px;
  }
  .searchHeader .searchBox {
    width: 100%;
    #pageBth {
      display: none;
    }
  }
  .searchHeader h2 {
    margin: 35px 0;
  }
  .breadcrumb {
    overflow-x: auto;
  }
  .breadcrumb::-webkit-scrollbar {
    display: none;
  }
  :deep(.el-breadcrumb) {
    display: flex;
    .el-breadcrumb__item {
      white-space: nowrap;
    }
  }
}
</style>
