<template>
  <div class="helpHome">
    <div class="hotArticle" id="article">
      <div class="title">
        <span class="Text" style="margin-right:10px;">热门文章</span>
        <el-button type="text" style="color:#2b82ff;" @click="changeHot">
          换一换 <i class="el-icon-refresh-right"></i>
        </el-button>
      </div>
      <ul class="hotArticleList">
        <li v-for="hot in this.hotArticle" :key="hot.id">
          <span class="dot">•</span>
          <router-link :to="`/help/${hot.id}?type=1`" class="hotTitle"
            ><span class="hotName">{{ hot.name }}</span></router-link
          >
          <i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </div>

    <div class="homeBlock" v-for="block in this.module" :key="block.id">
      <div class="blockTitle">
        <div class="titleLine"></div>
        <div class="titleName">{{ block.name }}</div>
      </div>
      <div class="blcokContent">
        <div class="component" v-for="son in block.son" :key="son.id">
          <a>
            <div class="card-header">
              <!-- <i class="iconfont icon-wangluo"></i> -->
              <img
                :src="`${base.admin_url}/static/index/images/document_home/${son.icon}`"
                alt=""
                class="iconImg"
                style="width:30px;height:30px;"
              />
              <span class="card-name">{{ son.name }}</span>
            </div>
            <ul class="card-content">
              <li v-for="article in son.article" :key="article.id">
                <span class="dot">•</span>
                <router-link :to="`/help/${article.id}?type=1`" v-if="article.type == 1" class="title">
                  <span>{{ article.name }}</span>
                </router-link>
                <a :href="`${base.admin_url}/viewb/${article.id}`" target="_blank" v-else class="title">
                  <span>{{ article.name }}</span>
                </a>
                <i class="el-icon-arrow-right"></i>
              </li>
            </ul>
            <div class="card-bottom">
              <a :href="`${son.path}`" target="_blank">
                <span class="all">查看全部</span>
              </a>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import { nextTick } from 'vue'
import base from "../../request/base";
export default {
  name: 'HelpHome',
  data() {
    return {
      base: base,
      userInfo: '',
      token: '',
      hotArticle: [],
      module: [],
      page: 2,
      type: '',
      driver: ''
    }
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
      } else {
        this.token = ''
      }
    }
    this.changeWidth()
    this.getHomeInfo()
  },
  beforeUnmount() {
    if (this.drive != undefined) {
      this.driver.reset()
    }
  },
  methods: {
    changeWidth() {
      if (this.$route.name == 'helpHome') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
    },
    wxShare(config) {
      // console.log(config);
      //微信分享
      wx.config(config)

      wx.ready(function() {
        const baseUrl = this.base
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '帮助首页-YinoCloud帮助中心', // 分享标题
          desc: `${baseUrl.url}/hc/helpHome`, // 分享描述
          link: `${baseUrl.url}/hc/helpHome`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${baseUrl.admin_url}/static/index/images/YinoCloud.png`, // 分享图标
          success: function() {
            // 设置成功
          }
        })
        wx.updateTimelineShareData({
          title: '帮助首页-YinoCloud帮助中心', // 分享标题
          link: `${baseUrl.url}/hc/helpHome`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${baseUrl.admin_url}/static/index/images/YinoCloud.png`, // 分享图标
          success: function() {
            // 设置成功
          }
        })
      })
    },
    async getHomeInfo() {
      const taskUrl = location.search.substr(1)
      const isTask = window.localStorage.getItem('isTask')

      const { data: res } = await this.$http.post('bot/home', {
        token: this.token
      })
      this.hotArticle = res.data.pop_article
      this.module = res.data.data
      this.wxShare(res.config)

      if (taskUrl == 'task' && !isTask) {
        window.localStorage.setItem('isTask', 1)
        nextTick(() => {
          this.driver = new Driver({
            opacity: 0.5,
            allowClose: false
          })

          this.driver.highlight({
            element: '#article',
            popover: {
              title: '新手任务',
              description: '点击任意一篇文章标题链接，进入文章详情页，即可完成任务哦！',
              closeBtnText: '确定',
              position: 'top'
            }
          })
        })
      }
    },
    async changeHot() {
      const { data: res } = await this.$http.post('bot/ajax_home', {
        token: this.token,
        page: this.page
      })
      this.hotArticle = res.data
      this.page = res.page
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.el-icon-arrow-right {
  display: none;
}
.helpHome {
  max-width: 1280px;
  margin: 60px auto;
  .hotArticle {
    padding: 20px 18px 20px 24px;
    background-color: hsla(0, 0%, 100%, 0.4);
    border-radius: 8px;
    margin-bottom: 50px;
    .hotArticleList {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        width: 23%;
        height: 30px;
        line-height: 30px;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .hotName {
          font-size: 14px;
          color: #595959;
        }
        .hotName:hover {
          color: #2b82ff;
        }
        .hotTitle {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .dot {
    margin-right: 8px;
  }
  .homeBlock {
    margin-bottom: 40px;
    :deep(.el-link) {
      justify-content: start;
    }
    .blockTitle {
      min-height: 20px;
      height: 28px;
      display: flex;
      font-weight: 500;
      font-size: 18px;
      color: #262626;
      margin-bottom: 30px;
      .titleLine {
        min-height: 20px;
        width: 4px;
        margin-right: 12px;
        background-color: #2b82ff;
      }
      .titleName {
        line-height: 28px;
      }
    }
    .blcokContent {
      min-height: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .component {
        width: 32%;
        height: 275px;
        box-shadow: 0 2px 24px rgba(28, 76, 186, 0.08);
        border-radius: 8px;
        background: #fff;
        margin-bottom: 40px;
        opacity: 0.8;
        .card-header {
          display: flex;
          align-items: center;
          height: 24px;
          margin: 30px 30px 24px;
          .iconImg {
            margin-right: 8px;
          }
          .card-name {
            font-size: 18px;
            font-weight: 600;
            color: #262626;
          }
        }
        :deep(.el-card__header) {
          border-bottom: none;
        }
        :deep(.el-card__body) {
          padding: 0;
        }
        .card-content {
          margin: 0 30px;
          li {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 16px;

            .title {
              width: 100%;
              font-size: 16px;
              color: #262626;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title:hover {
              color: #2b82ff;
            }
          }
        }
      }
      .card-bottom {
        border-top: 1px solid #eff0f1;
        padding: 13px 0 13px 40px;
        a {
          font-size: 16px;
          line-height: 24px;
          color: #2b82ff;
          cursor: pointer;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .blcokContent::after {
      content: '';
      width: 32%;
    }
  }
}

@media screen and (max-width: 768px) {
  .el-icon-arrow-right {
    display: block;
  }
  .helpHome {
    max-width: 100%;
    padding-bottom: 20px;
    margin: 30px auto;
    .hotArticle {
      background: #fff;
    }
  }
  .hotArticleList {
    li {
      width: 100% !important;
      height: 50px !important;
      border-bottom: 1px solid #dcdfe6;
    }
    li:last-child {
      border-bottom: none;
    }
    .hotName {
      font-size: 16px !important;
    }
  }
  .component {
    width: 100% !important;
    height: auto !important;
    .card-header {
      margin: 15px 30px 0 !important;
    }
    .card-content {
      li {
        width: 100% !important;
        height: 50px !important;
        border-bottom: 1px solid #dcdfe6;
        margin-bottom: 0 !important;
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
