<template>
  <el-container class="center-container">
    <el-header>
      <div style="cursor: pointer">
        <el-space :size="10" spacer="|">
          <router-link to="/login">
            <img src="@/assets/yllogo_02.png" alt="" v-cloak />
          </router-link>
          <router-link to="/hc">
            <span class="hcText">帮助中心</span>
          </router-link>
        </el-space>
      </div>
      <div>
        <div class="headSearch searchHide" style="width: 340px">
          <el-row :gutter="10">
            <el-col :span="18">
              <el-autocomplete v-model.trim="keyword" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false"
                size="small" prefix-icon="el-icon-search" placeholder="请输入关键词" clearable @select="handleSelect"
                @keyup.enter="getSearchResult"></el-autocomplete>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" size="small" style="margin-left: 10px" @click="getSearchResult">搜索</el-button>
            </el-col>
          </el-row>
        </div>
        <i class="el-icon-search" style="display: none" @click="MSearch"></i>
        <!-- 手机端搜索区域 -->
        <div class="searchDrawer">
          <el-drawer v-model="searchDrawer" direction="ttb" size="100%" @close="searchDrawerClose">
            <div class="MSearchHead">
              <el-autocomplete v-model.trim="keyword" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false"
                prefix-icon="el-icon-search" placeholder="请输入关键词" clearable @select="handleSelect"
                @keyup.enter="getSearchResult"></el-autocomplete>
              <el-button type="text" @click="searchDrawerCancle">取消</el-button>
            </div>
          </el-drawer>
        </div>
      </div>
    </el-header>
    <el-main>
      <div class="searchHeader" style="text-align:center">
        <h2 class="MainHead" v-show="this.$route.name == 'helpHome'">请问需要什么帮助？</h2>
        <div class="searchBox">
          <el-autocomplete ref="autoInputRef" v-model.trim="keyword" :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false" prefix-icon="el-icon-search" placeholder="请输入关键词" clearable @select="handleSelect"
            @click="MSearch" @keyup.enter="getSearchResult" :input-style="{ opacity: 0.8 }"></el-autocomplete>
          <el-button type="primary" id="pageBth" style="margin-left: 10px" @click="getSearchResult">搜索</el-button>
        </div>
      </div>
      <router-view></router-view>

      <!-- 贴边栏 -->
      <div class="AffixBox">
        <ul>
          <!--          <li>-->
          <!--            <el-popover placement="left-start" :width="80" trigger="hover">-->
          <!--              <div style="text-align: center">-->
          <!--                <el-image style="width: 80px; height: 80px" :src="ylisux" fit="fill"></el-image>-->
          <!--              </div>-->
          <!--              <template #reference>-->
          <!--                <i class="iconfont icon-weixin"></i>-->
          <!--              </template>-->
          <!--            </el-popover>-->
          <!--          </li>-->
          <li>
            <el-popover placement="left-start" trigger="hover">
              <span class="Text">前往YinoLink易诺官网</span>
              <template #reference>
                <el-link :href="`${base.official_url}?invite=ychelp`" :underline="false" target="_blank">
                  <i class="iconfont icon-guanwangfangwen"></i>
                </el-link>
              </template>
            </el-popover>
          </li>
          <li>
            <el-popover placement="left-start" trigger="hover">
              <span class="Text">前往YinoLink周5出海营销学院</span>
              <template #reference>
                <el-link :href="`${base.ac_url}?source=ychelp`" :underline="false" target="_blank">
                  <i class="iconfont icon-zhaoxueyuan"></i>
                </el-link>
              </template>
            </el-popover>
          </li>
        </ul>
      </div>
    </el-main>
    <el-footer style="z-index:100003;">
      <div class="ylLink">
        <el-link :href="`${base.official_url}?invite=ychelp`" :underline="false" target="_blank" class="goGuan">
          <span class="Text">前往YinoLink易诺官网</span>
        </el-link>
        <el-link :href="`${base.ac_url}?source=ychelp`" :underline="false" target="_blank">
          <span class="Text">前往YinoLink周5出海营销学院</span>
        </el-link>
      </div>
      <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank"
        style="width: 100%; text-align: center">
        <span class="Text PCCopyright">
          杭州千凤文化传媒有限公司版权所有Copyright © 2020
          <span><img src="@/assets/officeIcon.png" alt="" /></span><a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017562" rel="noreferrer"
            target="_blank">浙公网安备33011002017562</a>
        </span>
        <span class="Text MCopyright" style="display: none">
          杭州千凤文化传媒有限公司版权所有Copyright © 2020<br />
          <span><img src="@/assets/officeIcon.png" alt="" /></span><a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017562" rel="noreferrer"
            target="_blank">浙公网安备33011002017562</a>
        </span>
      </el-link>
    </el-footer>
  </el-container>
</template>

<script>
import $ from 'jquery'
import base from "../../request/base";
export default {
  name: 'HelpCenter',
  props: ['query', 'page'],
  data() {
    return {
      base: base,
      userInfo: '',
      token: '',
      searchDrawer: false,
      keyword: '',
      ylisux: `${base.official_url}/media/home/15834846459675.jpg`
    }
  },
  created() {
    this.getURLParams(window.location.href)
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
      } else {
        this.token = ''
      }
    }
    this.changeWidth()
    window.addEventListener('scroll', this.showHeadBg, true)
  },
  methods: {
    changeWidth() {
      if (this.$route.name == 'hc') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
    },
    showHeadBg() {
      // 页面滚动事件
      var boxTop = $('.el-main').scrollTop()
      if (boxTop >= 100) {
        $('.el-header').css({
          backgroundColor: '#FFF',
          boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.1)'
        })
        $('.searchHide').fadeIn()
      } else {
        $('.el-header').css({
          backgroundColor: 'transparent',
          boxShadow: 'none'
        })
        $('.searchHide').hide()
      }
    },
    async getURLParams(url) {
      const taskUrl = location.search.substr(1)
      var type = url.split('?')[1]

      // 防止官网文章内链接跳转输入框内获取url的source后缀
      if (type != undefined) {
        var source = type.split('=')[0]
      }

      if (!type || source == 'source' || taskUrl == 'task') {
        return
      } else {
        var type2 = decodeURI(type.split('=')[1])
        this.keyword = type2
      }
    },
    // 输入框远程查询
    async querySearchAsync(queryString, cb) {
      const { data: res } = await this.$http.post('bot/search_title', {
        keyword: this.keyword,
        token: this.token
      })
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].value = res.data[i].name
      }
      cb(res.data)
    },
    handleSelect(item) {
      this.searchDrawer = false
      this.$router.push({
        name: 'help',
        params: {
          id: item.id
        },
        query: {
          type: 1
        }
      })
    },
    // 点击按钮搜索
    getSearchResult() {
      this.$bus.emit('getSearchResult', this.keyword)
      this.$router.push({
        name: 'search',
        query: {
          query: this.keyword
        }
      })
      this.searchDrawer = false
    },
    // 打开搜索抽屉
    MSearch() {
      this.searchDrawer = true
    },
    // 取消搜索抽屉
    searchDrawerCancle() {
      this.searchDrawer = false
    },
    // 关闭drawer事件
    searchDrawerClose() {
      // this.keyword = ''
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.center-container {
  height: 100%;
}

.el-header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #fff;
  // border-bottom: 1px solid #dcdfe6;
  z-index: 1;

  .hcText {
    font-size: 16px;
    color: #000;
    opacity: 0.85;
  }

  img {
    width: 200px;
    height: auto;
  }

  :deep(.el-autocomplete) {
    width: 100%;
  }

  .searchHide {
    display: none;
  }
}

.el-main {
  padding-top: 70px;
  background: url(../../assets/helpBg.jpeg) no-repeat;
  background-color: #E0EFFE;
}

.searchHeader {
  h2 {
    font-size: 30px;
    margin: 70px 0;
  }

  .searchBox {
    display: flex;
    justify-content: space-between;
    width: 580px;
    margin: 0 auto;

    :deep(.el-autocomplete) {
      width: 100%;
    }
  }
}

.el-footer {
  background-color: #f5f5f7;
  padding: 30px 0 20px 0;
  height: auto !important;
  text-align: center;
}

.searchDrawer {
  display: none;
}

.PCCopyright {
  img {
    vertical-align: bottom;
  }
}

.AffixBox {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  li {
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2b82ff;
    border-top: 1px solid #fff;

    .iconfont {
      font-size: 30px;
      color: #fff;
    }
  }

  li:first-child {
    border-top: none;
  }

  li:hover {
    background: #409eff;
  }
}

:deep(.el-popover.el-popper) {
  min-width: 0;
}

.ylLink {
  margin-bottom: 15px;

  .goGuan {
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .searchDrawer {
    display: block;
  }

  .el-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: none;
    // background-color: #fff !important;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  }

  .el-main {
    padding-top: 100px;
  }

  :deep(.el-drawer__body) {
    padding: 0;
  }

  .article-main {
    padding: 20px !important;
  }

  .menuBox {
    // position: absolute !important;
    // height: 100%;
    // z-index: 9;
    display: none;
  }

  .el-menu {
    border-right: none;
  }

  .el-header img {
    width: 150px;
  }

  .headSearch {
    display: none !important;
  }

  .icon-caidan,
  .el-icon-search {
    display: block !important;
    font-size: 24px;
    color: #000;
  }

  .MSearchHead {
    display: flex;
    align-items: center;
    padding: 3vw;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;

    :deep(.el-autocomplete) {
      margin-right: 10px;
    }
  }

  .AffixBox {
    display: none;
  }

  .searchHeader .searchBox {
    width: 100%;

    #pageBth {
      display: none;
    }
  }

  .searchHeader h2 {
    margin: 30px 0;
  }

  .el-footer {
    padding: 10px 0;
  }

  .PCCopyright {
    display: none;
  }

  .MCopyright {
    display: block !important;
    font-size: 12px;

    img {
      vertical-align: bottom;
    }
  }

  .AffixBox {
    display: none;
  }

  .ylLink {
    margin-bottom: 10px;

    .goGuan {
      margin-right: 6px;
    }

    span {
      font-size: 12px;
    }
  }
}</style>
