<template>
  <el-header>
    <i class="iconfont icon-caidan" style="display: none" @click="openMenu"></i>
    <div style="cursor: pointer">
      <el-space :size="10" spacer="|">
        <router-link to="/login">
          <img src="@/assets/yllogo_02.png" alt="" v-cloak />
        </router-link>
        <router-link to="/hc">
          <span class="hcText">帮助中心</span>
        </router-link>
      </el-space>
    </div>
    <div>
      <div class="headSearch" style="width: 340px">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-autocomplete v-model.trim="keyword" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false"
              size="small" prefix-icon="el-icon-search" placeholder="请输入内容" clearable @select="handleSelect"
              @keyup.enter="getSearchResult"></el-autocomplete>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" size="small" style="margin-left: 10px" @click="getSearchResult">搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <i class="el-icon-search" style="display: none" @click="MSearch"></i>
      <!-- 手机端搜索区域 -->
      <div class="searchDrawer">
        <el-drawer v-model="searchDrawer" direction="ttb" size="100%" @close="searchDrawerClose">
          <div class="MSearchHead">
            <el-autocomplete v-model.trim="keyword" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false"
              prefix-icon="el-icon-search" placeholder="请输入内容" clearable @select="handleSelect"></el-autocomplete>
            <el-button type="text" @click="searchDrawerCancle">取消</el-button>
          </div>
        </el-drawer>
      </div>
    </div>
  </el-header>

  <!-- 手机端菜单区域 S -->
  <el-drawer v-model="menuDrawer" title="所有文章" direction="ltr" size="85%">
    <el-scrollbar height="100%">
      <el-aside width="100%">
        <el-menu ref="menu" :collapse="false" :default-active="$route.params.id" :router="true" @select="menuSelect"
          background-color="#fff">
          <template v-for="menu in menus" :key="menu.id">
            <!-- 一级导航-->
            <el-sub-menu v-if="menu.data && menu.data.length" :index="menu.id + ''">
              <template #title>{{ menu.name }}</template>
              <template v-for="submenu in menu.data" :key="submenu.id">
                <!-- 二级导航-->
                <el-sub-menu v-if="submenu.data && submenu.data.length" :index="submenu.id + ''">
                  <template #title> {{ submenu.name }}</template>
                  <!-- 三级导航--文章-->
                  <el-menu-item v-for="item in submenu.data" :key="item.id" :index="item.id + ''" :route="item.route">
                    <template #title>
                      <span><i class="el-icon-document"></i></span>
                      <span>{{ item.name }}</span>
                    </template>
                  </el-menu-item>
                </el-sub-menu>

                <!-- 二级导航--文章-->
                <el-menu-item v-else :index="submenu.id + ''" :route="submenu.route">
                  <template #title><span>{{ submenu.name }}</span></template>
                </el-menu-item>
              </template>
            </el-sub-menu>
            <!-- 一级导航--文章（一般不存在这种情况）-->
            <el-menu-item v-else :index="menu.id + ''" :route="menu.route">{{ menu.name }}</el-menu-item>
          </template>
        </el-menu>
      </el-aside>
    </el-scrollbar>
  </el-drawer>
  <!-- 手机端菜单区域 E -->

  <!--  帮助中心内容-->
  <el-container>
    <div class="menuBox" style="position: relative; background-color: #f5f5f7">
      <i :class="(isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left') + ' toggle-btn'"
        @click="isCollapse = !isCollapse"></i>
      <!-- PC侧边文档导航 -->
      <el-scrollbar height="100%">
        <el-aside :width="isCollapse ? '0px' : '300px'">
          <el-menu ref="menu" :collapse="false" :default-active="$route.params.id" :router="true"
            background-color="#f5f5f7" active-text-color="#2b82ff" @select="menuSelect">
            <template v-for="menu in menus" :key="menu.id">
              <!-- 一级导航-->
              <el-sub-menu v-if="menu.data && menu.data.length" :index="menu.id + ''">
                <template #title>{{ menu.name }}</template>
                <template v-for="submenu in menu.data" :key="submenu.id">
                  <!-- 二级导航-->
                  <el-sub-menu v-if="submenu.data && submenu.data.length" :index="submenu.id + ''">
                    <template #title> {{ submenu.name }}</template>
                    <!-- 三级导航--文章-->
                    <el-menu-item v-for="item in submenu.data" :key="item.id" :index="item.id + ''" :route="item.route">
                      <template #title>
                        <span><i class="el-icon-document"></i></span>
                        <span>{{ item.name }}</span>
                      </template>
                    </el-menu-item>
                  </el-sub-menu>

                  <!-- 二级导航--文章-->
                  <el-menu-item v-else :index="submenu.id + ''" :route="submenu.route">
                    <template #title><span>{{ submenu.name }}</span></template>
                  </el-menu-item>
                </template>
              </el-sub-menu>
              <!-- 一级导航--文章（一般不存在这种情况）-->
              <el-menu-item v-else :index="menu.id + ''" :route="menu.route">{{ menu.name }}</el-menu-item>
            </template>
          </el-menu>
        </el-aside>
      </el-scrollbar>
    </div>

    <!-- 文章内容显示-->
    <el-main class="article-main">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="crumb in breadcrumb" :key="crumb.id" :to="crumb.route">
          {{ crumb.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>

      <article-content v-if="$route.query.type == 1" v-bind:id="$route.params.id" :replace="true"></article-content>
      <el-row v-else-if="list.length" class="lists">
        <el-space size="large">
          <el-card class="list-item" v-for="item in list" :key="item.id" :body-style="{ padding: '0px' }">
            <el-link class="list-item-link" type="default" :underline="false"
              :href="item.route.path + '?type=' + item.route.query.type">{{ item.name }}</el-link>
          </el-card>
        </el-space>
      </el-row>
      <el-empty v-else description="暂无内容"></el-empty>

      <!-- 贴边栏 -->
      <!-- <div class="AffixBox"> -->
      <!-- <ul> -->
      <!-- <!~~          <li>~~> -->
      <!-- <!~~            <el-popover placement="left-start" :width="80" trigger="hover">~~> -->
      <!-- <!~~              <div style="text-align: center">~~> -->
      <!-- <!~~                <el-image style="width: 80px; height: 80px" :src="ylisux" fit="fill"></el-image>~~> -->
      <!-- <!~~              </div>~~> -->
      <!-- <!~~              <template #reference>~~> -->
      <!-- <!~~                <i class="iconfont icon-weixin"></i>~~> -->
      <!-- <!~~              </template>~~> -->
      <!-- <!~~            </el-popover>~~> -->
      <!-- <!~~          </li>~~> -->
      <!-- <li> -->
      <!-- <el-popover placement="left-start" trigger="hover"> -->
      <!-- <span class="Text">前往YinoLink易诺官网</span> -->
      <!-- <template #reference> -->
      <!-- <el-link :href="`${base.official_url}?invite=ychelp`" :underline="false" target="_blank"> -->
      <!-- <i class="iconfont icon-guanwangfangwen"></i> -->
      <!-- </el-link> -->
      <!-- </template> -->
      <!-- </el-popover> -->
      <!-- </li> -->
      <!-- <li> -->
      <!-- <el-popover placement="left-start" trigger="hover"> -->
      <!-- <span class="Text">前往YinoLink周5出海营销学院</span> -->
      <!-- <template #reference> -->
      <!-- <el-link :href="`${base.admin_url}?source=ychelp`" :underline="false" target="_blank"> -->
      <!-- <i class="iconfont icon-zhaoxueyuan"></i> -->
      <!-- </el-link> -->
      <!-- </template> -->
      <!-- </el-popover> -->
      <!-- </li> -->
      <!-- </ul> -->
      <!-- </div> -->

      <el-backtop target=".article-main" :visibility-height="50" />
    </el-main>
  </el-container>
</template>

<script>
import ArticleContent from './Article.vue'
import $ from 'jquery'
import base from '../../request/base'

export default {
  name: 'Index',
  components: { ArticleContent },
  data() {
    return {
      base: base,
      userInfo: '',
      token: '',
      isType: '',
      menus: [],
      isCollapse: false,
      selectedId: null,
      breadcrumb: [],
      list: [],
      menuDrawer: false,
      searchDrawer: false,
      keyword: '',
      ylisux: `${base.official_url}/media/home/15834846459675.jpg`,
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.breadcrumb = this.dealBreadCrumbs(this.menus)
        this.dealLists()
        // 搜索跳转时刷新页面
        this.$router.go(0)
      }
    },
  },
  created() {
    this.getURL(window.location.href)
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
      } else {
        this.token = ''
      }
    }
    this.$nextTick(() => {
      //PC端 使右键和复制失效
      document.oncontextmenu = new Function('event.returnValue=false')
      document.onselectstart = new Function('event.returnValue=false')

      //ios
      document.oncontextmenu = function (e) {
        e.preventDefault()
      }
      document.onselectstart = function (e) {
        e.preventDefault()
      }
      //安卓
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault()
      })
      document.ontouchend = function () {
        throw new Error('NO ERRPR:禁止长按弹出')
      }
    })
    this.getMenus()
  },
  methods: {
    async getURL(url) {
      var type = url.split('&')[1]
      if (type != undefined) {
        var type1 = type.split('=')[1]
        var login = type.split('=')[0]
        if (login == 'login') {
          window.localStorage.setItem('type', type1)
        }
      }
    },
    async getMenus() {
      if (this.$route.name == 'help') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
      const { data: res } = await this.$http.post('bot/menu', {
        token: this.token,
      })
      this.menus = this.dealMenus(res.data)
      this.dealLists()
      this.breadcrumb = this.dealBreadCrumbs(this.menus)
    },
    dealMenus(menus) {
      if (!menus || !menus.length) return menus
      menus.forEach((menu, menuId, menus) => {
        if (!menus[menuId].hasOwnProperty('route')) {
          menus[menuId].route = { path: '/help/' + menu.id, query: { type: menu.type } }
          menu.data &&
            menu.data.length &&
            menu.data.forEach((submenu, submenuId, menu) => {
              if (!menu[submenuId].hasOwnProperty('route')) {
                menu[submenuId].route = { path: '/help/' + menu[submenuId].id, query: { type: submenu.type } }
              }
              submenu.data &&
                submenu.data.length &&
                submenu.data.forEach((item, itemId, submenu) => {
                  if (!submenu[itemId].hasOwnProperty('route'))
                    submenu[itemId].route = { path: '/help/' + submenu[itemId].id, query: { type: item.type } }
                })
            })
        }
      })
      return menus
    },
    dealBreadCrumbs(menus) {
      let currentId = this.$route.params.id
      let breadcrumb = []
      menus.forEach((menu) => {
        //一级菜单  判断是否加入面包屑导航
        if (currentId && currentId === menu.id.toString()) {
          breadcrumb.push(menu)
          return
        }
        menu.data &&
          menu.data.length &&
          menu.data.forEach((submenu) => {
            //二级菜单  判断是否加入面包屑导航
            if (currentId && currentId === submenu.id.toString()) {
              breadcrumb.push(menu)
              breadcrumb.push(submenu)
              return
            }
            submenu.data &&
              submenu.data.length &&
              submenu.data.forEach((item) => {
                //三级菜单  判断是否加入面包屑导航
                if (currentId && currentId === item.id.toString()) {
                  breadcrumb.push(menu)
                  breadcrumb.push(submenu)
                  breadcrumb.push(item)
                  return
                }
              })
          })
      })
      return breadcrumb
    },
    dealLists() {
      let currentId = this.$route.params.id
      for (let menu of this.menus) {
        if (currentId == 'home' || currentId == menu.id) {
          this.list = menu.data

          return false
        }
        if (!menu.data || !menu.data.length) continue
        for (let submenu of menu.data) {
          if (currentId == submenu.id) {
            this.list = submenu.data
            return false
          }
        }
      }
    },
    // 输入框远程查询
    async querySearchAsync(queryString, cb) {
      const { data: res } = await this.$http.post('bot/search_title', {
        keyword: this.keyword,
        token: this.token,
      })
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].value = res.data[i].name
      }
      cb(res.data)
    },
    handleSelect(item) {
      this.searchDrawer = false
      this.$router.push({
        name: 'help',
        params: {
          id: item.id,
        },
        query: {
          type: 1,
        },
      })
    },
    // 点击按钮搜索
    getSearchResult() {
      this.$router.push({
        name: 'search',
        query: {
          query: this.keyword,
        },
      })
      this.$bus.emit('getSearchResult', this.keyword)
    },
    // 打开菜单栏
    openMenu() {
      this.menuDrawer = true
    },
    // 菜单栏选中关闭抽屉
    menuSelect() {
      this.menuDrawer = false
    },
    // 打开搜索抽屉
    MSearch() {
      this.searchDrawer = true
    },
    // 取消搜索抽屉
    searchDrawerCancle() {
      this.searchDrawer = false
    },
    // 关闭drawer事件
    searchDrawerClose() {
      this.keyword = ''
    },
  },
}
</script>

<style scoped lang="less">
* {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/

  -webkit-user-select: none;
  /*webkit浏览器*/

  -khtml-user-select: none;
  /*早起浏览器*/

  -moz-user-select: none;
  /*火狐浏览器*/

  -ms-user-select: none;
  /*IE浏览器*/

  user-select: none;
  /*用户是否能够选中文本*/
}

a {
  text-decoration: none;
}

.el-container {
  padding-top: 70px;
  height: 100%;
}

.el-header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #dcdfe6;
  z-index: 1;

  .hcText {
    font-size: 16px;
    color: #000;
    opacity: 0.85;
  }

  img {
    width: 200px;
    height: auto;
  }

  :deep(.el-autocomplete) {
    width: 100%;
  }
}

.el-menu {
  height: 100%;
}

.el-sub-menu {
  .el-menu-item {
    display: flex;
    align-items: center;
    line-height: 20px;
    white-space: normal;

    i {
      line-height: 2.5;
    }

    span {
      // display: -webkit-box;
      display: flex;
      align-items: center;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 44px;
    }
  }
}

.el-aside {
  height: 100%;
}

:deep(.el-breadcrumb__inner) {
  font-size: 14px;
  color: #000;
  opacity: 0.65;
}

:deep(.el-breadcrumb__inner.is-link:hover) {
  opacity: 1;
}

.toggle-btn {
  position: absolute;
  top: 14px;
  right: -18px;
  font-size: 20px;
  color: #606266;
  z-index: 999;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c0c4cc;
  border-radius: 50%;
  padding: 3px;
}

.toggle-btn:hover {
  color: #fff;
  background: #2b82ff;
}

.article-main {
  padding: 20px 40px !important;
}

.list-item-link {
  width: 260px;
  padding: 20px;
  align-items: flex-start;
  display: block;
}

.lists {
  padding-top: 20px;
}

.el-space {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;

  :deep(.el-space__item) {
    margin-bottom: 20px;
  }
}

.el-header {
  .el-space {
    :deep(.el-space__item) {
      margin-bottom: 0;
    }
  }
}

:deep(.el-drawer__body) {
  padding: 0;
}

:deep(.el-card:hover) {
  border: 1px solid #2b82ff;
}

.AffixBox {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  li {
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2b82ff;
    border-top: 1px solid #fff;

    .iconfont {
      font-size: 30px;
      color: #fff;
    }
  }

  li:first-child {
    border-top: none;
  }

  li:hover {
    background: #409eff;
  }
}

:deep(.el-popover.el-popper) {
  min-width: 0;
}

@media screen and (max-width: 768px) {
  .el-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  }

  :deep(.el-drawer__body) {
    padding: 0;
  }

  .article-main {
    padding: 20px !important;
  }

  .menuBox {
    // position: absolute !important;
    // height: 100%;
    // z-index: 9;
    display: none;
  }

  .el-menu {
    border-right: none;
  }

  .el-header img {
    width: 150px;
  }

  .headSearch {
    display: none;
  }

  .icon-caidan,
  .el-icon-search {
    display: block !important;
    font-size: 24px;
    color: #000;
  }

  .MSearchHead {
    display: flex;
    align-items: center;
    padding: 3vw;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;

    :deep(.el-autocomplete) {
      margin-right: 10px;
    }
  }

  .AffixBox {
    display: none;
  }
}
</style>
